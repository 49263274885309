import React, {useContext} from 'react'
import { GlobalDataContext } from '../../context/context'
import { ButtonContent } from './boton/ButtonContent'

const VideoTiktok = ({ linkVideo, image, title, text }) => {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div
            className='bgCountentVideo'
            style={{
                backgroundImage: `url("${image ? image : rpdata?.stock?.[0]}")`
            }}
        >
            <div className='countentVideoInfo '>
                <div className='info'>
                    <h3>{title}</h3>
                    <p>{text}</p>
                    <ButtonContent btnclases={'botonWhite'} btnStyle='three' />
                </div>
                <iframe
                    title="Promotional video"
                    className='w-[90%] h-[280px] md:h-[360px] md:w-auto lg:h-[420px] lg:w-auto mx-auto rounded-3xl relative top-[80px] md:top-[150px]'
                    id="ytplayer"
                    type="text/html"
                    src={`https://firebasestorage.googleapis.com/v0/b/videos-a7d6e.appspot.com/o/landscapingjjlosprimosllc_on_TikTok2b.mp4?alt=media&token=70da690e-f15b-44dc-8f51-951a44ce65ac`}
                    frameborder="0" allowfullscreen />
            </div>
        </div>
    )
}

export default VideoTiktok


